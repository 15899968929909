<template>
  <encode-decode
    :uuid="uuid"
    actionID="19"
    i18nKey="xml_decode"
    :middlewareID="middlewareID"
    :agentID="agentID"
    v-model="agent"
    :variables="variables"
    ref="encode-decode-component"
    @saved="onSaved"
    :agentBlockID="agentBlockID"
    @agentClosed="$emit('agentClosed')"
  >
    <template #label-icon-left>{{$t("common.terms.xml")}}</template>
    <template #icon-left>
      <b-icon icon="file-code" scale="2" variant="success" class="mr-1"></b-icon>
    </template>
    <template #icon-right>
      <b-icon icon="map" scale="2" variant="success" class="ml-1"></b-icon>
    </template>
    <template #label-icon-right>{{$t("common.terms.map")}}</template>

  </encode-decode>
</template>

<script>

import EncodeDecode from './EncodeDecode.vue'
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

    components: {
      EncodeDecode,
      
    },
    props: {
      middlewareID: {
        type: Number,
        required: true,
      },
      agentID: {
        type: Number,
        default: undefined,
      },
      value: {
        type: NormalizedAgent,
        default: undefined
      },
      uuid: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: "40%"
      },
      agentBlockID: {
        type: [ Number, String ],
        default: 1,
      },
      variables:{
        type: Object,
        default: undefined
      }
    },
    computed: {
      agent: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      toSaveRegs(){
        return this.$refs['encode-decode-component'].toSaveRegs
      } 
    },
    methods: {
      onSaved(payload) {
        this.$emit('saved', payload)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>